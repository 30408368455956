<template>
    <t-combobox v-model="cc" @change="c => { if (c) $emit('change', c) }" options-label="name_"
        :options-search="searchCompany" :placeholder="$t('section.company.combobox.title')">

        <template v-slot="{ selected, opt }">
            <div class="flex flex-nowrap items-center">
                <t-avatar class="mr-2" :company="opt" :size="1.75" />
                <span class="block truncate" :class="{ 'font-medium': selected, 'font-normal': !selected }">
                    {{ opt.name_ }}
                </span>
                <span v-if="selected" class="ml-auto self-center pr-3">
                    <t-icon-check class="h-5 w-5" aria-hidden="true" />
                </span>
            </div>
        </template>

        <template v-slot:end="{ active }" v-if="!$props.disableAdd">
            <div class="w-full block py-2 px-3" :class="{ 'bg-ocean text-white': active, 'text-ocean': !active }"
                @click="createCompany(true)">
                <t-icon-plus class="h-4 w-4 inline-block" /> {{ $t('section.company.combobox.create_pro') }}
            </div>
        </template>
        <template v-slot:end1="{ active }" v-if="!$props.disableAdd">
            <div class="w-full block py-2 px-3" :class="{ 'bg-ocean text-white': active, 'text-ocean': !active }"
                @click="createCompany(false)">
                <t-icon-plus class="h-4 w-4 inline-block" /> {{ $t('section.company.combobox.create_ind') }}
            </div>
        </template>
    </t-combobox>
</template>


<script lang="ts">
import { defineComponent, computed, PropType } from 'vue';

import { Criteria, CriteriaOperator } from '@tolemac/grpc_web_api/tolemac/pub/filter';
import { Company, ICompany } from '@tolemac/grpc_web_api/tolemac/pub/model';

import plugins from '@/plugins';

export default defineComponent({
    name: 't-combobox-company',
    mixins: [] as unknown as [Omit<GlobalComponentsMixin<'t-combobox'>, 'emits'>],
    emits: {
        change: (_: ICompany) => true,
        ['update:modelValue']: (_?: ICompany) => true
    },
    props: {
        modelValue: Object as PropType<ICompany>,

        disableAdd: Boolean,

        limit: Number,

        /** api load relations */
        relations: Array as PropType<string[]>,
        /** extra criterias for search company by name  */
        criterias: Array as PropType<Criteria[]>
    },
    setup(props, { emit }) {
        const { $api, util } = plugins

        const cc = computed({
            get() { return props.modelValue },
            set(c?: ICompany) {
                emit('update:modelValue', c)
                if (c) emit('change', c)
            }
        })

        return {
            cc,

            async createCompany(pro: boolean) {
                const newC = await util.company.openModal({ type: pro ? Company.CompanyType.PROFESSIONAL : Company.CompanyType.INDIVIDUAL })
                if (newC) {
                    emit('update:modelValue', newC)
                    emit('change', newC)
                }
            },

            searchCompany(query: string) {
                const criterias = props.criterias || []
                if (query)
                    criterias.push({ field: "name_", value: `%${query}%`, operator: CriteriaOperator.I_LIKE })
                return $api.company.find({
                    relations: props.relations,
                    criterias,
                    limit: props.limit || 5
                }).then(v => v.values)
            }
        }
    }

})

</script>