<template>
  <t-combobox class="col-span-6 sm:col-span-3" v-model="model" v-model:count="countSearch" :options-search="search"
    :options-label="l => l.name" options-key="code">
    <template v-slot="{ selected, opt }">
      <div class="flex flex-nowrap">
        <span v-if="opt.codeCountry" class="pr-2">{{ opt.flagCountry }}</span>
        <span>{{ opt.name }}</span>
        <span v-if="selected" class="ml-auto self-center pr-3">
          <t-icon-check class="h-5 w-5" aria-hidden="true" />
        </span>
      </div>
    </template>
  </t-combobox>
</template>

<script lang="ts">
import { computed, defineComponent, ref, nextTick, PropType } from 'vue'

import plugins from '@/plugins'

export default defineComponent({
  name: 't-lang-combobox',
  props: {
    modelValue: String,
    exclude: { type: Array as PropType<Array<string>>, default: () => [] }
    // maybe `modelValue`: string[] without `exclude` more logical
  },
  mixins: [] as unknown as [GlobalComponentsMixin<'t-combobox'>],
  setup(props, { emit }) {
    const { $languages } = plugins

    const countSearch = ref<null | number>(null)

    return {
      model: computed({
        get() {
          return props.modelValue ? $languages.get(props.modelValue) : undefined
        },
        set(lang?: LANG) {
          emit('update:modelValue', lang?.code)
          // refresh search because exclude change
          nextTick(() => countSearch.value = null)
        }
      }),

      countSearch,
      search: (query?: string) => {
        let max = (!query || query.length === 1) ? 5 :
          query.length === 2 ? 8 :
            query.length === 3 ? 15 :
              query.length === 4 ? 25 : null
        return $languages.search(query, props.exclude, max)
      }
    }
  }
})

</script>