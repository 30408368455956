<template>
    <t-select v-model="tax" :options="taxes" :options-label="optsLabel" :options-key="optsKey">

        <template v-slot="{ opt, active, selected }">
            <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate pl-8 pr-4 py-2']">
                {{ optsLabel(opt) }}
            </span>
            <span v-if="selected"
                :class="[active ? 'text-white' : 'text-ocean', 'absolute inset-y-0 left-0 flex items-center pl-1.5']">
                <t-icon-check class="h-5 w-5" aria-hidden="true" />
            </span>
        </template>

        <template v-slot:end="{ active }">
            <div class="w-full block py-2 px-1" @click="addTaxe()"
                :class="{ 'bg-ocean text-white': active, 'text-ocean': !active }">
                <t-icon-plus class="h-4 w-4 inline-block" /> {{ $t('page.settings_billing.section_tax.action_add_new') }}
            </div>
        </template>

    </t-select>
</template>

<script lang="ts">
import { defineComponent, computed, ref, PropType } from 'vue';

import { ITaxRate } from '@tolemac/grpc_web_api/tolemac/pub/model';

import plugins from '@/plugins';

export default defineComponent({
    name: 't-tax-select',
    props: {
        modelValue: Object as PropType<ITaxRate>
    },
    mixins: [] as unknown as [GlobalComponentsMixin<'t-select'>],
    async setup(props, { emit }) {
        const { $api, $formatter, util } = plugins

        const taxes = ref<ITaxRate[]>()
        const tax = computed({
            get() { return props.modelValue || { name: '', value: 0 } },
            set(t: ITaxRate) { emit('update:modelValue', t) }
        })

        async function loadTaxes() {
            taxes.value = await $api.taxRate.find({}).then(v => v.values)
        }

        await loadTaxes()

        return {
            taxes,
            tax,

            async addTaxe() {
                const newTaxe = await util.taxes.openModal()
                await loadTaxes()
                if (newTaxe)
                    tax.value = newTaxe
            },

            optsLabel: function (t: ITaxRate) {
                if (props.optionsLabel)
                    if (typeof props.optionsLabel === 'string')
                        return `${t[props.optionsLabel as keyof ITaxRate]}`
                    else
                        return props.optionsLabel(t)
                return !t ? '' :
                    t.name ? `${t.name} - ${$formatter.number(t.value || 0, 2, true)} %` :
                        `${$formatter.number(t.value || 0, 2, true)} %`
            },
            optsKey: props.optionsKey ?? 'id'
        }
    }
})
</script>