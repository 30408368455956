import { createApp, onErrorCaptured } from 'vue'
import { IonContent, IonPage, IonicVue } from '@ionic/vue';
import { StatusCode } from "grpc-web"

import App from '@/views/App.vue'
import router from '@/router';

import { ErrorCode } from '@tolemac/grpc_web_api/tolemac/pub/common';

import { toastPlugin, toastController } from "@/plugins/toast"
import { emitterPlugin, emitter } from "@/plugins/emitter"
import { apiPlugin } from "@/plugins/api"
import { i18n, i18nPlugin, formatter } from "@/plugins/i18n"
import { modalPlugin } from "@/plugins/modal"
import { cookiesPlugin } from "@/plugins/cookies"
import { pinia } from "@/plugins/store"
import { utilPlugin } from "@/plugins/util"
import { envPlugin } from "@/plugins/env"

import { webComponents, type PluginOptions } from "@tolemac/web-components"

import plugins from "@/plugins"

import '@/theme/ionic.pcss';

/* Ionic - Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Ionic - Basic CSS for apps built with Ionic */
// import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Ionic - Optional CSS utils that can be commented out */
// import '@ionic/vue/css/padding.css';
// import '@ionic/vue/css/float-elements.css';
// import '@ionic/vue/css/text-alignment.css';
// import '@ionic/vue/css/text-transformation.css';
// import '@ionic/vue/css/flex-utils.css';
// import '@ionic/vue/css/display.css';

/* Animate.css */
// import 'animate.css/source/_base.css';
// import 'animate.css/source/flippers/flipInY.css';

import '@/theme/style.pcss';

import "@tolemac/web-components/style.css"

import SVG from '@/components/SVG.vue'
import RouterView from '@/components/RouterView.vue'

import { CheckIcon, XMarkIcon, ArrowPathIcon, ChevronDownIcon, ChevronUpIcon, FunnelIcon as FunnelIconOutline, TrashIcon, PencilIcon, PlusIcon, MinusIcon } from '@heroicons/vue/24/outline'
import { FunnelIcon as FunnelIconSolid } from '@heroicons/vue/24/solid'

const ionicConfig: any = { //Parameters<typeof setupConfig>[0] = {
  animated: false,
  // rippleEffect: true, false if animated false
  swipeBackEnabled: false,
  rippleEffect: false,
}

export const vueApp = createApp(App)

  .use(i18nPlugin)
  .use(IonicVue, ionicConfig)

  .use(apiPlugin)
  .use(router)
  .use(cookiesPlugin)
  .use(pinia)

  .use(emitterPlugin)
  .use(toastPlugin)
  .use(modalPlugin)

  .use(utilPlugin)

  .use(envPlugin)

  .use<PluginOptions>(webComponents, {
    formatter: {
      get timezone() { return formatter.timezone },
      get dateLocale() { return formatter.dateLocale },
      get dateFormat() { return formatter.dateLocale?.formatLong?.date({ width: 'short' }) as string },
      get timeFormat() { return formatter.timeLocale?.formatLong?.time({ width: 'short' }) as string },
      get dateTimeFormat() { return formatter.dateLocale?.formatLong?.dateTime({ width: 'short' }) as string }
    },
    translate: i18n.t,
    emitter: {
      dirty(uid: number) { emitter.lock(uid) },
      clean(uid: number) { emitter.unlock(uid) }
    },
    // components use in public page. others are registered in ./views/main/index.vue 
    register: [
      't-input',
      't-input-icon',
      't-input-email',
      't-input-tel',
      't-input-password',
      't-input-search',
      't-input-url',
      't-button',
      't-button-icon',
      't-switch',
      't-select',
      't-combobox',
    ]
  })

  .component(SVG.name!, SVG)
  .component(RouterView.name!, RouterView)

  .component('t-icon-check', CheckIcon)
  .component('t-icon-x-mark', XMarkIcon)
  .component('t-icon-arrow-path', ArrowPathIcon)
  .component('t-icon-chevron-down', ChevronDownIcon)
  .component('t-icon-chevron-up', ChevronUpIcon)
  .component('t-icon-funnel-solid', FunnelIconSolid)
  .component('t-icon-funnel-outline', FunnelIconOutline)
  .component('t-icon-trash', TrashIcon)
  .component('t-icon-pencil', PencilIcon)
  .component('t-icon-plus', PlusIcon)
  .component('t-icon-minus', MinusIcon)

  .component('ion-content', IonContent)
  .component('ion-page', IonPage)

const LifecycleHooks_ERROR_CAPTURED = 'ec'

// vueApp.config.warnHandler = async (msg, instance, trace) => {
//   console.warn(msg, trace)
//   if (instance) (instance as any).xx = 'boo'
// }

vueApp.config.errorHandler = async (error, instance, info) => {
  // console.log((instance as any).xx, (error as any)?.message)
  // if ((instance as any).xx === 'boo') return

  // check onErrorCapured hook of current instance component.
  // if hook return false stop propagation
  const hooksErrorCaptured = ((instance as any)?.$[LifecycleHooks_ERROR_CAPTURED] || []) as Parameters<typeof onErrorCaptured>[0][]
  for (const hookErrorCaptured of hooksErrorCaptured)
    if (hookErrorCaptured(error, instance, info) === false)
      return

  if ((error as any).errorType === 'ErrorApi') {

    const err = error as ErrorApi

    console.error(
      `${info} [${instance?.$.type.name}]: ${err.message}`,
      `(`,
      err.errorCodeGroup || err.errorCode,
      err.errorDetail ? `${err.errorDetail})\n` : ')\n',
      ...(err.stackList || []).map(_s => ['\tat', _s, '\n']).flat()
    )

    const _instance = instance || vueApp.config.globalProperties

    // specific error code
    if (err.errorCode === ErrorCode.UNAUTHORIZED_LOGOUT) {
      toastController.warn(_instance.$enum.ERROR_CODE.translate(err.errorCode), err);
      _instance.$store.account?.logout()
      return
    }
    if (err.errorCode === ErrorCode.SERVICE_UNAVAILABLE || err.errorCode === StatusCode.UNAVAILABLE) {
      toastController.error(_instance.$enum.ERROR_CODE.translate(ErrorCode.SERVICE_UNAVAILABLE), err)
      return
    }

    // dynamic translation of error code
    let message = ''
    let warn = true
    if (err.errorCode && err.errorDetail) {
      // example: errors. + BAD_REQUEST_UNICITY + __ + UQ_SERVICE_REFERENCE
      message = _instance.$enum.ERROR_CODE.translate(`${ErrorCode[err.errorCode]}__${err.errorDetail}`, { fallbckTranslate: `${ErrorCode[err.errorCode]}` })
    }
    if (!message && err.errorCodeGroup && err.errorCodeGroup >= 400 && err.errorCodeGroup < 500) {
      message = _instance.$enum.ERROR_CODE.translate(err.errorCode)
    }
    if (!message && err.errorCodeGroup === 2 || (err.errorCodeGroup && err.errorCodeGroup >= 500)) {
      message = _instance.$enum.ERROR_CODE.translate(err.errorCode)
      warn = false
    }

    // if the translation is successfull, display toast
    // else fallback to toast with technical error message
    if (message) {
      if (warn)
        await toastController.warn(message, err)
      else
        await toastController.error(message, err)
    } else {
      await toastController.error(plugins.$t('errors.INTERNAL_SERVER_ERROR'), error)
    }
  } else if ((error as any).errorType === 'ErrorToast') {
    const err = error as IErrorToast
    if (err.level === 'info') {
      console.info(err)
      await toastController.info(err.message)
    } else if (err.level === 'warn') {
      console.warn(err)
      await toastController.warn(err.message)
    } else {
      console.error(err)
      await toastController.error(err.message, err)
    }
  } else {
    console.error(error)
    await toastController.error(plugins.$t('errors.INTERNAL_WEB_ERROR'), (error as any)?.message)
  }
}

router.onError(error => {
  // if module is not found refresh window. new version deploy.
  if (error && typeof error.message === 'string' && (error.message as string).startsWith('error loading dynamically imported module')) {
    console.log('Module not found. There may be a new version available. Reload', error)
    window.location.reload()
  }
});

Promise.all([
  router.isReady(),
  i18nPlugin.isReady()
])
  .then(() => vueApp.mount('#app'));